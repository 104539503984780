import { useQuery } from "react-query";
import { getQrApi } from "./api/api";
import { useEffect, useState } from "react";
import { faRotateRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useSigninSetCookie } from "./hook/useSigninSetCookie";
import { useMediaQuery } from "react-responsive";
import { siteTypeState } from "recoil/commonUiAtom";
import { useRecoilValue } from "recoil";

export default function QrCode({ onConfirm }: { onConfirm: () => void }) {
  const [isBlur, setIsBlur] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const signinSetCookie = useSigninSetCookie();
  const siteType = useRecoilValue(siteTypeState);

  const width800 = useMediaQuery({
    query: "screen and (max-width: 800px)"
  });

  const { isFetching, data, refetch } = useQuery(
    "qrcode",
    () => getQrApi("web", ""),
    {
      staleTime: 0,
      cacheTime: 0
    }
  );

  useEffect(() => {
    if (data && !width800) {
      const expiredUtcTime = new Date(data.data.expire_time).getTime();

      const interval = setInterval(() => {
        const currentUtcTime = Math.floor(Date.now() / 1000);

        const callbackApi = async () => {
          try {
            await axios
              .post(data.data.callback_url, { union_id: data.data.union_id })
              .then((res) => {
                if (res.data.result === 1) {
                  window.dispatchEvent(
                    new CustomEvent("loginValue", { detail: true })
                  );
                  signinSetCookie(res.data.data.user_info);
                  setIsBlur(true);
                  onConfirm();

                  if (siteType === "game") {
                    window.location.reload();
                  }

                  return () => clearInterval(interval);
                } else if (res.data.result === -81) {
                  setIsBlur(true);
                  clearInterval(interval);
                }
              });
          } catch (error) {
            console.log(error, "callback");
          }
        };

        callbackApi();

        if (currentUtcTime >= expiredUtcTime) {
          setIsBlur(true);
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [data, width800]);

  const onClickRefresh = () => {
    if (isBlur) {
      refetch();
      setIsBlur(false);
      setIsHover(false);
    }
  };

  return (
    <div className="qr">
      {!isFetching ? (
        <img
          src={`data:image/png;base64,${data?.data.qr_base64_data}`}
          className={isBlur ? "blur" : ""}
        />
      ) : (
        <FontAwesomeIcon icon={faSpinner} pulse />
      )}

      {isBlur && (
        <FontAwesomeIcon
          icon={faRotateRight}
          spin={isHover ? true : false}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={onClickRefresh}
        />
      )}
    </div>
  );
}

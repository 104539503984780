import styled, { css } from "styled-components";

export const Modal = {
  Container: styled.div<{ showModal: boolean }>`
    position: fixed;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    z-index: 99999;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

    ${({ showModal }) =>
      showModal &&
      css`
        top: 0;
        opacity: 1;
      `}

    .shadow {
      z-index: -1 !important;
      display: inline-block !important;
    }
  `,

  Content: styled.div`
    * {
      font-family: "NotoKr", "Helvetica Neue", "Noto Sans SC", "Malgun Gothic",
        Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", "M PLUS 1p", sans-serif;
      word-break: normal;
      -webkit-font-smoothing: antialiased;
      font-size: 1rem;
    }

    margin: auto;
    position: relative;
    background: #f5f6f9;
    border-radius: 8px;
    padding: 3.75rem 4.375rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @media screen and (max-width: 460px) {
      padding: 3rem;
      width: 100%;
    }
  `
};

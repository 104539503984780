import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./QrArea.scss";
import {
  faAppStoreIos,
  faGooglePlay
} from "@fortawesome/free-brands-svg-icons";
import { VFUN_IMAGE_DNS } from "utils/config/Config";
import { useState } from "react";
import QrCode from "./QrCode";
import { redirectURL } from "utils/CommonFunc";

function HowTo({ isVisible }: { isVisible: boolean }) {
  return (
    <div className={`how-to-wrap ${isVisible ? "show" : "hide"}`}>
      <img src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/how-to-qr-min.png`} />
      <ul>
        <li>1.VFUN 앱을 실행합니다.</li>
        <li>2.앱 상단의 QR코드 로그인 을 선택합니다.</li>
        <li>3.QR코드 스캔후 로그인 버튼을 눌러주세요.</li>
      </ul>
    </div>
  );
}

export default function QrArea({ onConfirm }: { onConfirm: () => void }) {
  const [isHover, setIsHover] = useState({
    playS: false,
    howTo: false,
    appS: false
  });

  const GOOGLE_PLAY_URL =
    "https://play.google.com/store/apps/details?id=com.valofe.android.vfun&pli=1";
  const APP_STORE_URL = "https://apps.apple.com/us/app/vfun/id6670229532";

  return (
    <div id="qrArea">
      <div className="title">
        <div>
          {isHover.appS || isHover.playS
            ? `다운로드`
            : `QR코드를 스캔하여 로그인하세요.`}
        </div>
        {isHover.appS || isHover.playS ? null : (
          <div
            className="gray-circle"
            onMouseEnter={() =>
              setIsHover((prev) => ({ ...prev, howTo: true }))
            }
            onMouseLeave={() =>
              setIsHover((prev) => ({ ...prev, howTo: false }))
            }
          >
            <FontAwesomeIcon icon={faQuestion} />
          </div>
        )}
      </div>

      <div className="qr-wrap">
        <HowTo isVisible={isHover.howTo} />

        <div className={`qr-cont ${!isHover.howTo ? "show" : "hide"}`}>
          <QrCode onConfirm={onConfirm} />
          <p>
            {isHover.appS || isHover.playS
              ? `QR코드를 찍고 모바일에서 VFUN 앱을 다운로드 받으세요!`
              : `공용 네트워크, 공용 PC라면 안전을 위해 QR코드로 로그인해 주세요.`}
          </p>
          <div className="btns">
            <button
              className="gray btn"
              onMouseEnter={() =>
                setIsHover((prev) => ({ ...prev, playS: true }))
              }
              onMouseLeave={() =>
                setIsHover((prev) => ({ ...prev, playS: false }))
              }
              onClick={() => redirectURL(GOOGLE_PLAY_URL, "_blank", false)}
            >
              <FontAwesomeIcon icon={faGooglePlay} />
              Play Store
            </button>
            <button
              className="gray btn"
              onMouseEnter={() =>
                setIsHover((prev) => ({ ...prev, appS: true }))
              }
              onMouseLeave={() =>
                setIsHover((prev) => ({ ...prev, appS: false }))
              }
              onClick={() => redirectURL(APP_STORE_URL, "_blank", false)}
            >
              <FontAwesomeIcon icon={faAppStoreIos} />
              App Store
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
